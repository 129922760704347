































import {Component, Vue, Watch} from 'vue-property-decorator';
import moment from 'moment';
import {vxm} from '@/store';
import NoContent from '@/components/NoContent.vue';
import Loader from '@/components/Loader.vue';
import LoaderBottom from '@/components/LoaderBottom.vue';
import {NotificationTypes} from '@/constants/notificationTypes';
import NotificationInterface from '@/types/NotificationInterface';
import debounce from '@/utils/debounce';

@Component({
  components: {NoContent, Loader, LoaderBottom},
})
export default class Notifications extends Vue {
  loading = false;
  notifications = [] as NotificationInterface[];
  mentions = [] as NotificationInterface[];
  NotificationTypes = NotificationTypes;
  limit = 10;
  notificationsPage = 1;
  notificationsComplete = false;
  mentionsPage = 1;
  mentionsComplete = false;
  showMentions = false;
  debounceHandler = () => {
    /* Will be replaced */
  };

  get noNotificationsMessage(): string {
    return `You have no ${this.showMentions ? 'mentions' : 'notifications'}...\nTry to check later!`;
  }
  get unreadMentions(): boolean {
    return !!vxm.user.mentionCounter;
  }

  @Watch('showMentions')
  onShowMentionsChange() {
    if (this.showMentions && !this.mentions.length && !this.mentionsComplete) {
      this.getMentions();
    }
  }

  mounted() {
    this.debounceHandler = debounce(this.onScroll, 300);
    window.addEventListener('scroll', this.debounceHandler, false);
    this.getNotifications();
  }
  beforeDestroy() {
    window.removeEventListener('scroll', this.debounceHandler, false);
  }

  onScroll(): void {
    if (this.showMentions) {
      if (this.mentionsComplete) {
        return;
      }
      if (this.isPageBottom()) {
        this.getMentions();
      }
    } else {
      if (this.notificationsComplete) {
        return;
      }
      if (this.isPageBottom()) {
        this.getNotifications();
      }
    }
  }
  getNotifications(): void {
    vxm.user
      .getNotifications({
        page: this.notificationsPage,
        limit: this.limit,
        offset: this.notificationsPage === 1 ? 0 : vxm.user.notificationCounter,
        type: '-mention',
      })
      .then(async (res: {data: {items: []; total: number}}) => {
        this.notifications.push(...res.data.items);
        if (this.notificationsPage === 1) {
          await vxm.user.resetNotificationCounter();
        }
        if (this.limit * this.notificationsPage >= res.data.total + vxm.user.notificationCounter) {
          this.notificationsComplete = true;
        }
        this.notificationsPage += 1;
        await this.$nextTick();
        if (this.isPageBottom() && !this.notificationsComplete) {
          this.debounceHandler();
        }
      });
  }
  getMentions(): void {
    vxm.user
      .getNotifications({
        page: this.mentionsPage,
        limit: this.limit,
        offset: this.mentionsPage === 1 ? 0 : vxm.user.mentionCounter,
        type: 'mention',
      })
      .then(async (res: {data: {items: []; total: number}}) => {
        this.mentions.push(...res.data.items);
        if (this.mentionsPage === 1) {
          await vxm.user.resetMentionCounter();
        }
        if (this.limit * this.mentionsPage >= res.data.total + vxm.user.mentionCounter) {
          this.mentionsComplete = true;
        }
        this.mentionsPage += 1;
        await this.$nextTick();
        if (this.isPageBottom() && !this.mentionsComplete) {
          this.debounceHandler();
        }
      });
  }
  getUserAvatar(index: number): string {
    const sender = this.showMentions ? this.mentions[index].senderId : this.notifications[index].senderId;
    if (sender.avatar && sender.avatar.link) {
      return sender.avatar.link.medium || sender.avatar.link.origin || require('@/assets/icons/avatar-default.svg');
    } else {
      return require('@/assets/icons/avatar-default.svg');
    }
  }
  getNotificationMessage(type: string): string {
    switch (type) {
      case NotificationTypes.Comment: {
        return 'has commented your post';
      }
      case NotificationTypes.Like: {
        return 'has liked your post';
      }
      case NotificationTypes.FollowerNew: {
        return 'now follows you';
      }
      case NotificationTypes.FollowerAccepted: {
        return 'has accepted your request';
      }
      case NotificationTypes.Request: {
        return 'wants to follow your profile';
      }
      case NotificationTypes.Repost: {
        return 'has reposted your post';
      }
      case NotificationTypes.Mention: {
        return 'has mentioned you';
      }
      default: {
        return '';
      }
    }
  }
  deleteNotification(index: number): void {
    if (index < 0 && index >= (this.showMentions ? this.mentions.length : this.notifications.length)) {
      return;
    }
    this.loading = true;
    vxm.user
      .deleteNotification(this.showMentions ? this.mentions[index]._id : this.notifications[index]._id)
      .then(() => {
        this.showMentions ? this.mentions.splice(index, 1) : this.notifications.splice(index, 1);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  answerFollowRequest(followerId: string, accepted: boolean, index: number): void {
    if (index < 0 && index >= this.notifications.length) {
      return;
    }
    this.loading = true;
    vxm.user
      .answerFollowRequest({followerId, accepted})
      .then(() => {
        this.notifications.splice(index, 1);
        this.$toasted.show(accepted ? 'Request was successfully accepted.' : 'Request was successfully declined.', {
          className: 'toasted-info',
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }
  showDeleteButton(index: number): boolean {
    return this.showMentions
      ? this.mentions[index].type !== NotificationTypes.Request
      : this.notifications[index].type !== NotificationTypes.Request;
  }
  formatDate(date: string): string {
    return moment(date).fromNow();
  }
  isPageBottom(): boolean {
    return (
      document.documentElement.scrollTop + window.innerHeight >=
      document.documentElement.scrollHeight - this.getBottomLoaderHeight()
    );
  }
  getBottomLoaderHeight(): number {
    const bottomLoader = document.getElementById('bottom-loader');
    return bottomLoader ? bottomLoader.clientHeight : 0;
  }
}
