export enum NotificationTypes {
  Comment = 'comment',
  Like = 'like',
  Repost = 'repost',
  Request = 'follower_request',
  FollowerNew = 'follower_new',
  FollowerAccepted = 'follower_accepted',
  Mention = 'mention',
}

export type NotificationTypesType =
  | NotificationTypes.Comment
  | NotificationTypes.Like
  | NotificationTypes.Repost
  | NotificationTypes.Request
  | NotificationTypes.FollowerNew
  | NotificationTypes.FollowerAccepted
  | NotificationTypes.Mention;
